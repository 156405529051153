import React from "react"
import { Row, Container, Col } from "react-bootstrap"
import NavigationBar from "../components/NavigationBar"
import Jumbotron from "../components/Jumbotron"
import styled from "styled-components"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"

const header = "Club Events"
const pageSummary = "Details of our club events."

const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const ContentDiv = styled.div`
  flex-grow: 1;
  text-align: justify;
  text-justify: auto;
`

const ClubEventsPage = () => (
  <StyledContainer>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content={pageSummary} />
      <title>Lomond Roads Cycling Club - Club Events</title>
      <link rel="canonical" href="lomondroads.org/clubEvents" />
    </Helmet>
    <NavigationBar />
    <Jumbotron header={header} pageSummary={pageSummary} />
    <ContentDiv>
      <Container>
        <Row>
          <Col>
            <p>
              We orgnaise a series of confined club events on an annual basis.
              Once our schedule of events is confirmed for the rest of 2020, it
              will be made avaialble here.
            </p>

            <p>
              Additionally we orgnaise the Hugh Dornan Memorial Race on an
              annual basis which is an open event that is part of the Scottish
              National Alba Series. See the article linked below as well as the
              video for some further detail on this event:
              <br />
              <br />
              <a
                href="https://www.britishcycling.org.uk/scotland/article/20200512-Scottish-National-Alba-Series--Hugh-Dornan-Memorial-0?fbclid=IwAR2b8GEcc67Hjz85yHd1BAyY4AA6_C9ahFOckU9Bw57aomW7GMuFGMu5UWI"
                target="_blank"
              >
                British Cycling Article
              </a>
              <br />
              <br />
              <div
                className="video"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  paddingTop: 25,
                  height: 0,
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/5RTTVag5sVU"
                  frameBorder="0"
                  title="Race to Rosneath"
                />
              </div>
            </p>
          </Col>
        </Row>
      </Container>
    </ContentDiv>
    <Footer />
  </StyledContainer>
)

export default ClubEventsPage
